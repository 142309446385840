:root {
  --font-color: #333;
  --background-color: #eee;
  --link-color: #2D65B0;
  --background-color-alt3: #d1d1d1;
  --background-color-hover: #888;
}

[data-theme="dark"] {
  --font-color: #eee;
  --background-color: #333;
  --background-color-alt: #3f3f3f;
  --background-color-alt2: #363636;
  --background-color-alt3: #5e5e5e;
  --background-color-hover: #a5a5a5;
  --link-color: #eee;
}

body {
  background-color: var(--background-color);
  color: var(--font-color);
}

a {
  color: var(--link-color);
}

.table {
  color: var(--font-color);
}

.accordion {
  color: var(--font-color);
}

.accordion-item {
  color: var(--font-color);
  background-color: var(--background-color);
  border-color: var(--font-color);
}

.accordion-button {
  color: var(--font-color);
  background-color: var(--background-color);
}

.accordion-collapse {
  border-color: var(--font-color);
}

.accordion-button:not(.collapsed) {
  color: var(--font-color);
  background-color: var(--background-color);
}

.list-group-item {
  background-color: var(--background-color);
  border-color: var(--font-color);
  color: var(--font-color);
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  color: var(--font-color);
}

.table-striped>tbody>tr:nth-of-type(odd)>* :hover {
  color: var(--font-color);
}

.card-header {
  background-color: var(--background-color-alt2);
}

.card {
  background-color: var(--background-color-alt);
  border: 1px solid var(--background-color-alt3);
}

.toggle-theme-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.toggle-theme-wrapper span {
  font-size: 28px;
}

.toggle-theme {
  position: relative;
  display: inline-block;
  height: 34px;
  width: 60px;
}

.toggle-theme input {
  display: none;
}

.slider {
  background-color: #ccc;
  position: absolute;
  cursor: pointer;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition: .2s;
}

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 26px;
  left: 4px;
  position: absolute;
  transition: .4s;
  width: 26px;
}

.custom-navbar-link {
  font-family: 'Avenir-lt-medium';
  color: var(--link-color);
}

input:checked+.slider:before {
  transform: translateX(26px);
}

input:checked+.slider {
  background-color: #6495ed;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.modal-header {
  background-color: var(--background-color-alt);
  border-bottom: 1px solid var(--background-color-alt2);
}

.modal-body {
  background-color: var(--background-color);
}

.modal-footer {
  background-color: var(--background-color-alt);
  border-top: 1px solid var(--background-color-alt2);
}

.modal-header .btn-close {
  color: #fff !important;
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.md-toolbar-wrapper .md-toolbar {
  border-bottom: 1px solid var(--background-color-alt2);
  color: var(--font-color);
  background-color: var(--background-color);
}

.md-icon {
  color: var(--font-color);
}

.md-icon:hover {
  color: #333;
}

.md-content .md-input-wrapper textarea {
  background-color: var(--background-color) !important;
  scrollbar-color: var(--background-color-alt2) !important;
}

.md-content .md-preview-wrapper {
  background-color: var(--background-color);
}

.md {
  border: 1px solid var(--background-color-alt3);
  border-radius: .25rem;
}

.page-link {
  background-color: var(--background-color);
  border: 1px solid var(--background-color-alt3);
  color: var(--font-color);
}

.page-link:hover {
  background-color: var(--background-color-alt3);
  border: 1px solid var(--background-color-alt3);
  color: var(--font-color);
}

.btn-outline-primary {
  color: var(--font-color);
}

.form-control {
  background-color: var(--background-color);
  border-color: var(--background-color-alt3);
  color: var(--font-color);
}

.form-control:focus {
  background-color: var(--background-color-alt2);
  border-color: var(--background-color-alt);
  color: var(--font-color);
}

.dropdown-menu {
  background-color: var(--background-color);
  border-color: var(--background-color-alt);
  color: var(--font-color);
}

.dropdown-item {
  background-color: var(--background-color);
  border-color: var(--background-color-alt);
  color: var(--font-color);
}

.dropdown-item:hover {
  background-color: var(--background-color-hover);
}

.github-theme table tr th,
.github-theme table tr td {
  color: var(--font-color);
}

.form-check-input {
  background-color: var(--background-color);
  color: var(--font-color);
  border-color: var(--background-color-hover);
}

tbody,
td,
tfoot,
th,
thead,
tr {
  background-color: var(--background-color);
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 160px;
  height: 160px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: var(--font-color);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 16px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 16px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 64px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 112px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(48px, 0);
  }
}