@font-face {
	font-family: 'Avenir-lt';
	src: local('Avenir-lt'), url(./fonts/Avenir-lt/avenir-lt-std-35-light.ttf);
}

@font-face {
	font-family: 'Avenir-lt-oblique';
	src: local('Avenir-lt'), url(./fonts/Avenir-lt/avenir-lt-std-35-light-oblique.ttf);
}

@font-face {
	font-family: 'Avenir-lt-medium';
	src: local('Avenir-lt'), url(./fonts/Avenir-lt/avenir-lt-std-65-medium.ttf);
}

@font-face {
	font-family: 'Avenir-lt-medium-oblique';
	src: local('Avenir-lt'), url(./fonts/Avenir-lt/avenir-lt-std-65-medium-oblique.ttf);
}

body {
	margin: 0 !important;
	padding: 0 !important;
}

nav {
	padding: auto !important;
	margin: auto !important;
	padding-right: 12px !important
}

html {
	overflow-y: scroll;
}

.learnHeader {
	background-color: #2d65b0;
	border-radius: 0 0 10px 10px;
}

#content {
	min-height: 100vh;
}

.lsSlides {
	background-image: url("slidebg.png");
	background-repeat: no-repeat;
	background-size: contain;
	user-select: none;
}

#navbarScrollingDropdown {
	color: #fff;
}

#feedback-button:hover {
	cursor: pointer;
}