body {
  margin: 0;
  padding: 0;
  font-family: "Avenir-lt", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.artCard {
  margin-bottom: 15px;
}

.md-previewOnly {
  background: none !important;
}

.md-content .md-preview,
.md-content .md-html {
  overflow-wrap: normal !important;
  word-break: normal !important;
}

.tagBadge {
  display: inline-block;
  padding: .35em .65em;
  font-size: .75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
}

.bi {
  vertical-align: -.125em;
  pointer-events: none;
  fill: currentColor;
}

.dropdown-toggle {
  outline: 0;
}

.nav-flush .nav-link {
  border-radius: 0;
}

.scrollarea {
  overflow-y: auto;
}

.fw-semibold {
  font-weight: 600;
}

.lh-tight {
  line-height: 1.25;
}

.navbar {
  z-index: 2;
}

.close-navbar-toggler {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  cursor: pointer;
}

.close-navbar-toggler.collapsed {
  z-index: -1;
}

.activeCustom {
  background-color: #477cc2;
  padding: 15px;
  border-radius: 10px;
  transition: padding 500ms ease-in-out, background-color 1s ease-in-out;
  width: 100%;
}

.artTitle {
  background-color: #2d65b0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: min-content;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 20px;
  color: #fff;
}

@media screen and (max-width: 540px) {
  .artTitle h3 {
    font-size: 4vw;
  }
}

#add-school-form {
  transition: all 250ms ease-in-out;
}

.class-form {
  transition: all 900ms ease-in-out;
}